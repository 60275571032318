body, html {
  margin: 0;
  background-color: #f1f2f4 !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* @font-face {
  font-family: "Consolas";
  src: local("Consolas"),url(../fonts/Consolas.ttf) ;
} */
/* @font-face {
  font-family: "TTNorms";
  font-weight: 300;
  src: local("TTNorms"), url(../fonts/TTNorms-Light.otf) format("opentype");
} */
/* @font-face {
  font-family: "TTNorms";
  font-weight: 400;
  src: local("TTNorms"), url(../fonts/TTNorms-Regular.otf) format("opentype");
}
@font-face {
  font-family: "TTNorms";
  font-weight: 500;
  src: local("TTNorms"), url(../fonts/TTNorms-Medium.otf) format("opentype");
}
@font-face {
  font-family: "TTNorms";
  font-weight: 500;
  src: local("TTNorms"), url(../fonts/TTNorms-Bold.otf) format("opentype");
} */

@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: local("Lato"), url(../fonts/Lato-Regular.ttf) 
}

/* @font-face {
  font-family: "Lato";
  font-weight: 500;
  src: local("Lato"), url(../fonts/Lato-Medium.ttf) 
} */

@font-face {
  font-family: "Lato";
  font-weight: 500;
  src: local("Lato"), url(../fonts/Lato-Bold.ttf) 
}

/* @font-face {
  font-family: "TTNorms";
  font-weight: 600;
  src: local("TTNorms"), url(../fonts/TTNorms-Black.otf) format("opentype");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Our stuff */
.worktuallyInput:focus {
  border-color: #006ab3 !important;
}

.worktuallyButton:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.75);
}

.worktuallyLink {
  text-decoration: none;
  color: #006ab3 !important;
  font-weight: 500;
}

.worktually_adminLoginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customIcon {
  width: 24px;
  object-fit: cover;
}

.customIconButton {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 50px;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  min-width: 220px; /* 220px */
  background-color: #fff;
}

.tableHead .MuiTableRow-head .MuiTableCell-head {
  border-bottom: 0px;
  border-bottom-color: transparent;
}

.navDropdown {
  top: 1px !important;
}

/* only for vscode color codes testing */
.cTest {
  color: #ffffffad;
}

.inputModal .MuiOutlinedInput-root {
  border-radius: 50px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  /* 220px */
  background-color: #fff;
}
